import { envConfig } from '@env/config';

export const market = envConfig.NEXT_PUBLIC_APP_MARKET;
export const locale = envConfig.NEXT_PUBLIC_APP_LOCALE;
export const language = envConfig.NEXT_PUBLIC_APP_LANGUAGE;
export const brandCode = envConfig.NEXT_PUBLIC_APP_BRAND_CODE;

const config: {
  language: string;
  market: string;
  locale: string;
  brandCode: string;
} = {
  language,
  market,
  locale,
  brandCode,
};

export default config;
